const _temp0 = require("./directive/boomerang-checked-wb.js");
const _temp1 = require("./directive/boomerang-registration-promocode.js");
const _temp2 = require("./directive/days-or-hours-from-now.js");
const _temp3 = require("./directive/from-utc-to-local-date.js");
const _temp4 = require("./directive/history-back.js");
const _temp5 = require("./directive/reset-filter.js");
const _temp6 = require("./directive/scroll-to-top-on-click-pr.js");
const _temp7 = require("./directive/zet-bonus-activation.js");
module.exports = {
  "boomerang-checked-wb.js": _temp0,
  "boomerang-registration-promocode.js": _temp1,
  "days-or-hours-from-now.js": _temp2,
  "from-utc-to-local-date.js": _temp3,
  "history-back.js": _temp4,
  "reset-filter.js": _temp5,
  "scroll-to-top-on-click-pr.js": _temp6,
  "zet-bonus-activation.js": _temp7
}