import "@b2c/core/app/application.js";
import "./directive/**/*";import '@b2c/core/app/components/**/auth-with-deposit-init-form.js';
import '@b2c/core/app/components/**/auth-with-deposit-login.js';
import '@b2c/core/app/components/**/auth-with-deposit-paysystems.js';
import '@b2c/core/app/components/**/auth-with-deposit-popup.js';
import '@b2c/core/app/components/**/auth-with-deposit-registration.js';
import '@b2c/core/app/components/**/auth-with-deposit-registration-bonus.js';
import '@b2c/core/app/components/**/base.js';
import '@b2c/core/app/components/**/batman-banner1.js';
import '@b2c/core/app/components/**/batman-banner2.js';
import '@b2c/core/app/components/**/batman-banner3.js';
import '@b2c/core/app/components/**/batman-banner4.js';
import '@b2c/core/app/components/**/batman-banner5.js';
import '@b2c/core/app/components/**/batman-bonus-money-item1.js';
import '@b2c/core/app/components/**/batman-bonus-money-item2.js';
import '@b2c/core/app/components/**/batman-bonus-spin-item1.js';
import '@b2c/core/app/components/**/batman-bonus-spin-item2.js';
import '@b2c/core/app/components/**/batman-bonus-sport-item1.js';
import '@b2c/core/app/components/**/batman-bonus-sport-item2.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit-form1.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit-iframe-form1.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit1.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit2.js';
import '@b2c/core/app/components/**/batman-countdown2.js';
import '@b2c/core/app/components/**/batman-fast-registration-form1.js';
import '@b2c/core/app/components/**/batman-game-box-favourites1.js';
import '@b2c/core/app/components/**/batman-game-box1.js';
import '@b2c/core/app/components/**/batman-game-box2.js';
import '@b2c/core/app/components/**/batman-game-box3.js';
import '@b2c/core/app/components/**/batman-game-box4.js';
import '@b2c/core/app/components/**/batman-game-box5.js';
import '@b2c/core/app/components/**/batman-game-box6.js';
import '@b2c/core/app/components/**/batman-game-hall1.js';
import '@b2c/core/app/components/**/batman-game-hall2.js';
import '@b2c/core/app/components/**/batman-game-hall3.js';
import '@b2c/core/app/components/**/batman-game-hall4.js';
import '@b2c/core/app/components/**/batman-game-hall5.js';
import '@b2c/core/app/components/**/batman-game-hall6.js';
import '@b2c/core/app/components/**/batman-game-hall7.js';
import '@b2c/core/app/components/**/batman-game-item1.js';
import '@b2c/core/app/components/**/batman-game-item2.js';
import '@b2c/core/app/components/**/batman-game-item3.js';
import '@b2c/core/app/components/**/batman-game-item4.js';
import '@b2c/core/app/components/**/batman-icon-box1.js';
import '@b2c/core/app/components/**/batman-icon-box2.js';
import '@b2c/core/app/components/**/batman-info-page1.js';
import '@b2c/core/app/components/**/batman-modal-wrapper1.js';
import '@b2c/core/app/components/**/batman-modal-wrapper2.js';
import '@b2c/core/app/components/**/batman-popup-wrapper1.js';
import '@b2c/core/app/components/**/batman-popup-wrapper2.js';
import '@b2c/core/app/components/**/batman-popup-wrapper3.js';
import '@b2c/core/app/components/**/batman-popup-wrapper9.js';
import '@b2c/core/app/components/**/batman-promo-item1.js';
import '@b2c/core/app/components/**/batman-promo-list1.js';
import '@b2c/core/app/components/**/batman-recently-played-list1.js';
import '@b2c/core/app/components/**/batman-registration1.js';
import '@b2c/core/app/components/**/batman-shop-box1.js';
import '@b2c/core/app/components/**/batman-shop-box2.js';
import '@b2c/core/app/components/**/batman-shop-confirmation-popup1.js';
import '@b2c/core/app/components/**/batman-shop-confirmation-popup2.js';
import '@b2c/core/app/components/**/batman-shop-freespin-box1.js';
import '@b2c/core/app/components/**/batman-shop-freespin-box2.js';
import '@b2c/core/app/components/**/batman-shop-freespin-item1.js';
import '@b2c/core/app/components/**/batman-shop-freespin-item2.js';
import '@b2c/core/app/components/**/batman-shop-freespin-item3.js';
import '@b2c/core/app/components/**/batman-shop-item1.js';
import '@b2c/core/app/components/**/batman-shop-item2.js';
import '@b2c/core/app/components/**/batman-slider1.js';
import '@b2c/core/app/components/**/batman-tournament-item1.js';
import '@b2c/core/app/components/**/batman-tournament-item2.js';
import '@b2c/core/app/components/**/batman-tournament-item3.js';
import '@b2c/core/app/components/**/batman-ui-wrapper1.js';
import '@b2c/core/app/components/**/batman-view-layer1.js';
import '@b2c/core/app/components/**/batman-widget-last-deposit1.js';
import '@b2c/core/app/components/**/batman-widget-last-deposit2.js';
import '@b2c/core/app/components/**/batman-zimpler-paynplay-widget1.js';
import '@b2c/core/app/components/**/linda-app.js';
import '@b2c/core/app/components/**/linda-autobonus-popup.js';
import '@b2c/core/app/components/**/linda-bonus-activation-modal.js';
import '@b2c/core/app/components/**/linda-bonus-cancellation-modal.js';
import '@b2c/core/app/components/**/linda-cashbox-balance-information.js';
import '@b2c/core/app/components/**/linda-cashbox-deposit.js';
import '@b2c/core/app/components/**/linda-cashbox-deposit-form.js';
import '@b2c/core/app/components/**/linda-cashbox-popup.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw-cancel.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw-form.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw-iframe-form.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw-modal.js';
import '@b2c/core/app/components/**/linda-contact-bar.js';
import '@b2c/core/app/components/**/linda-cookie-banner.js';
import '@b2c/core/app/components/**/linda-error-modal.js';
import '@b2c/core/app/components/**/linda-footer.js';
import '@b2c/core/app/components/**/linda-form-date-selector.js';
import '@b2c/core/app/components/**/linda-game-categories.js';
import '@b2c/core/app/components/**/linda-game-field.js';
import '@b2c/core/app/components/**/linda-game-page.js';
import '@b2c/core/app/components/**/linda-game-top.js';
import '@b2c/core/app/components/**/linda-gdpr-registration-modal.js';
import '@b2c/core/app/components/**/linda-header.js';
import '@b2c/core/app/components/**/linda-homepage.js';
import '@b2c/core/app/components/**/linda-info-page.js';
import '@b2c/core/app/components/**/linda-info-popup.js';
import '@b2c/core/app/components/**/linda-lang-list.js';
import '@b2c/core/app/components/**/linda-lang-popup.js';
import '@b2c/core/app/components/**/linda-login-form.js';
import '@b2c/core/app/components/**/linda-login-popup.js';
import '@b2c/core/app/components/**/linda-lp-exchange-form.js';
import '@b2c/core/app/components/**/linda-menu-top.js';
import '@b2c/core/app/components/**/linda-missed-data-cancellation-modal.js';
import '@b2c/core/app/components/**/linda-missed-data-form.js';
import '@b2c/core/app/components/**/linda-modal-body.js';
import '@b2c/core/app/components/**/linda-notification.js';
import '@b2c/core/app/components/**/linda-payment-callback.js';
import '@b2c/core/app/components/**/linda-popup-body.js';
import '@b2c/core/app/components/**/linda-profile-bonus-list.js';
import '@b2c/core/app/components/**/linda-profile-change-password-form.js';
import '@b2c/core/app/components/**/linda-profile-page.js';
import '@b2c/core/app/components/**/linda-profile-settings.js';
import '@b2c/core/app/components/**/linda-promo-page.js';
import '@b2c/core/app/components/**/linda-promos.js';
import '@b2c/core/app/components/**/linda-registration-form.js';
import '@b2c/core/app/components/**/linda-registration-popup.js';
import '@b2c/core/app/components/**/linda-restoration-form.js';
import '@b2c/core/app/components/**/linda-restoration-new-pass-form.js';
import '@b2c/core/app/components/**/linda-restoration-popup.js';
import '@b2c/core/app/components/**/linda-search.js';
import '@b2c/core/app/components/**/linda-search-popup.js';
import '@b2c/core/app/components/**/linda-shop-deposit-popup.js';
import '@b2c/core/app/components/**/linda-shop-page.js';
import '@b2c/core/app/components/**/linda-sitemap.js';
import '@b2c/core/app/components/**/linda-sport-field.js';
import '@b2c/core/app/components/**/linda-sport-page.js';
import '@b2c/core/app/components/**/linda-tournament-page.js';
import '@b2c/core/app/components/**/linda-tournaments.js';
import '@b2c/core/app/components/**/linda-view-layer-five.js';
import '@b2c/core/app/components/**/linda-view-layer-four.js';
import '@b2c/core/app/components/**/linda-view-layer-one.js';
import '@b2c/core/app/components/**/linda-view-layer-three.js';
import '@b2c/core/app/components/**/linda-view-layer-two.js';
import '@b2c/core/app/components/**/linda-vip.js';
import '@b2c/core/app/components/**/linda-withdrawal-payment-callback.js';
import '@b2c/core/app/components/**/linda-zimpler-paynplay-login-popup.js';
import '@b2c/core/app/components/**/linda-zimpler-paynplay-page.js';
import '@b2c/core/app/components/**/linda-zimpler-registration-modal.js';
import '@b2c/core/app/components/**/not-found.js';
import '@b2c/core/app/components/**/pgw-widget.js';
import '@b2c/core/app/components/**/christmas-countdown.js';
import '@b2c/core/app/components/**/christmas-promo.js';
import '@b2c/core/app/components/**/christmas-promo-mobile.js';
import '@b2c/core/app/components/**/christmas-promo-popup.js';
import '@b2c/core/app/components/**/christmas-tournament.js';
import '@b2c/core/app/components/**/desc-easter-promo-popup.js';
import '@b2c/core/app/components/**/dvs-alert.js';
import '@b2c/core/app/components/**/dvs-item.js';
import '@b2c/core/app/components/**/dvs-page.js';
import '@b2c/core/app/components/**/dvs-popup.js';
import '@b2c/core/app/components/**/easter-countdown.js';
import '@b2c/core/app/components/**/easter-promo.js';
import '@b2c/core/app/components/**/easter-promo-mobile.js';
import '@b2c/core/app/components/**/easter-promo-popup.js';
import '@b2c/core/app/components/**/footer-default.js';
import '@b2c/core/app/components/**/halloween-countdown.js';
import '@b2c/core/app/components/**/halloween-promo.js';
import '@b2c/core/app/components/**/halloween-promo-mobile.js';
import '@b2c/core/app/components/**/halloween-tournament.js';
import '@b2c/core/app/components/**/linda-otp-components.js';
import '@b2c/core/app/components/**/linda-promo-wrapper.js';
import '@b2c/core/app/components/**/oktoberfest-holiday-welcome-popup.js';
import '@b2c/core/app/components/**/summer-after-game-popup.js';
import '@b2c/core/app/components/**/summer-completed-popup.js';
import '@b2c/core/app/components/**/summer-countdown.js';
import '@b2c/core/app/components/**/summer-countdown-short.js';
import '@b2c/core/app/components/**/summer-countdown-with-day.js';
import '@b2c/core/app/components/**/summer-exit-game-popup.js';
import '@b2c/core/app/components/**/summer-extra-progress.js';
import '@b2c/core/app/components/**/summer-holiday-item.js';
import '@b2c/core/app/components/**/summer-holiday-list.js';
import '@b2c/core/app/components/**/summer-how-work-popup.js';
import '@b2c/core/app/components/**/summer-landscape.js';
import '@b2c/core/app/components/**/summer-promo-page.js';
import '@b2c/core/app/components/**/summer-promo-wrapper.js';
import '@b2c/core/app/components/**/summer-reward-after-popup.js';
import '@b2c/core/app/components/**/summer-story-tell-modal.js';
import '@b2c/core/app/components/**/summer-super-reward-popup.js';
import '@b2c/core/app/components/**/summer-welcome-popup.js';
import '@b2c/core/app/components/**/world-cup-countdown.js';
import '@b2c/core/app/components/**/world-cup-popup-mobile1.js';
import '@b2c/core/app/components/**/world-cup-popup-mobile2.js';
import '@b2c/core/app/components/**/world-cup-popup1.js';
import '@b2c/core/app/components/**/world-cup-popup2.js';
import '@b2c/core/app/components/**/world-cup-promo.js';
import '@b2c/core/app/components/**/world-cup-promo-mobile.js';
import '@b2c/core/app/components/**/world-cup-sport-feed-list.js';
import '@b2c/core/app/components/**/world-cup-styles.js';
import '@b2c/core/app/components/**/world-cup-templates.js';
import '@b2c/core/app/components/base.js';